<template>
  <div id="subscription">
    <template v-if="loading">
      <loading />
    </template>
    <template v-else>
      <iframe
        :src="secured_path"
        style="
          width: 100%;
          height: 740px;
          border: none;
          overflow: hidden !important;
        "
      ></iframe>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import StoreManager from './../../store/store_manager'
import { fetchSchool, fetchAppUrl, fetchToken } from '@/assets/js/app_info.js'

const axios = require('axios')

export default {
  name: 'subscription',
  data() {
    return {
      pageTitle: 'Subscription Payment',
      loading: true,
      path: null,
      token: null,
      secured_path: null,
      reference: null,
      schoolId: null,
      school: null,
    }
  },
  watch: {},
  methods: {
    initialize() {
      this.loading = true
      console.log(this.token)
      axios
        .get(`${this.path}/initialize_subscription_payment`, {
          params: {
            email: 'abiaemma@gmail.com',
            amount: 120000,
            token: this.token,
          },
        })
        .then((response) => {
          console.log(response.data.data)
          this.reference = response.data.data.reference

          this.secured_path = response.data.data.authorization_url
          this.loading = false
        })
        .catch((error) => {
          console.log(error.message)
        })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [])

    this.initialize()

    fetchSchool().then((school) => {
      this.school = school
    })
    fetchAppUrl().then((path) => {
      this.path = path
    })
    fetchToken().then((token) => {
      this.token = token
    })
  },
}
</script>
